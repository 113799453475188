import { defineStore } from 'pinia'
import { SubmissionStatusType, SubmissionFilterParamsType } from '../_types/api-services/api.service'
import { SUBMISSIONS_STATUS_VALUE, SUBMISSIONS_STATUS } from '../_constants/common.constant'
import { UploadedFileType, HistoryListData, SubmissionHistory } from '../_types/components/commons/form'
import { DATA_COLLECTION_FILE_LABEL } from '../_constants/fileupload.constant'
import { useToast } from 'vue-toastification'
import { toRaw } from 'vue'
import { useI18NStore } from './i18n'
import { formatDate, findDifferentFields } from '../_helpers/common.helpers'
import { client } from '../_api-services/urls'
import ApiService from '../_api-services/api.service'
import { useAppStore } from './app'
import { components } from '../_api-services/openapi'
import { get, set } from '../_helpers/idb_keyval'
import SubmissionMessage from '../components/commons/SubmissionMessage.vue'
import { router } from '../router'
import { FormKitSchemaFormKit } from '@formkit/core'
import { API_STATUS } from '../_constants/api.constant'

const toast = useToast()

export type SubmissionType = components['schemas']['SubmissionOut']

type SubmissionsType = SubmissionType[]

interface SubmissionsState {
  submissions: SubmissionsType
  activeSubmission?: SubmissionType
  activeFile?: UploadedFileType[]
  activeSubmissionHistory?: SubmissionHistory[]
  activeRevisionSubmission?: SubmissionType | null
  revisedFields?: string[]
}

const initialState: SubmissionsState = {
  submissions: [],
  activeFile: [],
  activeRevisionSubmission: null,
  revisedFields: []
}

/**
 * Function to get field names based on FormSchema using field ids
 * @param nodes FormKitSchemaFormKit | undefined
 * @param fieldIds string []
 * @param field string formkit field key that will return
 * @returns string[]
 */
export function getFieldsName (nodes: FormKitSchemaFormKit | undefined, fieldIds: string[], field = 'label'): string[] {
  // Check if nodes or its children are undefined, throw an error if so
  if (typeof nodes === 'undefined' || typeof nodes?.children === 'undefined') throw new Error('Schemas are not exist')

  // Initialize an array to store schemas
  let schemas: FormKitSchemaFormKit[] = []

  // If nodes.children is an array, iterate over each node
  if (Array.isArray(nodes.children)) {
    (nodes?.children).forEach(node => {
      // Check if the node is not a string and has children
      if (typeof node !== 'string' && (node as FormKitSchemaFormKit)?.children !== undefined) {
        // If so, extract children and add them to the schemas array
        const children = (node as FormKitSchemaFormKit)?.children as FormKitSchemaFormKit[]
        schemas = [...schemas, ...children]
      }
    })
  }

  // Map over fieldIds to get corresponding field names
  return fieldIds.map(it => {
    // If fieldId contains '.', split it to extract repeater information
    if (it.includes('.')) {
      const repeaterId = it.split('.')
      if (repeaterId.length > 0) {
        // Find the schema corresponding to the repeaterId
        const findSchema = schemas.find(schema => schema.id === repeaterId[0])
        // If the schema is found and has children
        if (findSchema?.children !== undefined) {
          // Extract the field name and construct the final field name string
          const findFieldName = (findSchema?.children as FormKitSchemaFormKit[]).find(schema => schema.name === repeaterId[2])
          return findFieldName === undefined ? '' : findFieldName[field] ?? ''
        } else {
          // If the schema is not found or does not have children, return the original fieldId
          return it
        }
      }
    }
    // If fieldId does not contain '.', find the corresponding field name
    const findFieldName = schemas.find(schema => it === schema.id)
    // If field name is found, remove the $gettext wrapper and return the field name, otherwise return the original fieldId
    return findFieldName === undefined ? '' : findFieldName[field] !== undefined ? findFieldName[field] : it
  })
}

export const useSubmissionsStore = defineStore('submissions', {
  state: () => (initialState),
  getters: {
    getSubmissionByKey: (state) => {
      return (key: string) => state.submissions.find(s => s.key === key)
    },
    getSubmissionsByFormType: (state) => {
      return (formType: string) => state.submissions.filter(s => s.form_type === formType && s.status === SUBMISSIONS_STATUS_VALUE.verified)
    },
    getActiveFile: (state) => {
      return toRaw(state.activeFile)
    },
    getActiveRevision: (state) => {
      return toRaw(state.activeRevisionSubmission)
    },
    getOldestRevision: (state) => { // get the first revision
      return typeof state.activeSubmissionHistory !== 'undefined' ? toRaw(state.activeSubmissionHistory)[state.activeSubmissionHistory.length - 1] : undefined
    },
    getSubmissionHistoryWithMessages: (state) => {
      return (key: string, formSchema: FormKitSchemaFormKit | undefined): HistoryListData[] => {
        const submission = state.submissions.find(s => s.key === key)
        if ((state.activeSubmissionHistory == null) || (submission == null)) return []

        return state.activeSubmissionHistory.map((history, index): HistoryListData => {
          let message = null
          let status = 'created'
          if (history.type !== '+') {
            const prevHistory = typeof state.activeSubmissionHistory !== 'undefined' && (index + 1) < state.activeSubmissionHistory.length
              ? state.activeSubmissionHistory[index + 1]
              : { fields: {}, status: 1 }
            if (history.status !== prevHistory.status) {
              status = SUBMISSIONS_STATUS[history.status].toLocaleLowerCase()
              message = [
                useI18NStore().interpolate('%(message)s %(status)s', {
                  status: useI18NStore().gettext(status),
                  message: useI18NStore().gettext('Submission has been')
                })
              ]
            } else {
              const fieldsDifferences: string[] = findDifferentFields(history.fields, prevHistory?.fields ?? {})
              const fieldsDifferencesLabel = ((formSchema != null) ? getFieldsName(formSchema, fieldsDifferences) : fieldsDifferences)
              // insert spacing and `&` on the fields list
              const fieldsWithSpacing = fieldsDifferencesLabel.flatMap((item, index) =>
                index === fieldsDifferencesLabel.length - 2 ? [item, ' & '] : [item, ', ']
              )

              if (fieldsDifferences.length > 0) {
                message = [
                  '$gettext("These following fields:")',
                  ...fieldsWithSpacing,
                  '$gettext("has been updated")'
                ]
              } else {
                message = null
              }
            }
          } else {
            message = ['$gettext("Form created")']
          }

          if (history.fields?.status !== undefined) {
            status = submission.status === SUBMISSIONS_STATUS_VALUE.verified ? 'verified' : 'requested'
            message = [submission.status === SUBMISSIONS_STATUS_VALUE.verified ? '$gettext("Form has been verified")' : '$gettext("Form has been rejected")']
          }

          return {
            id: history.version,
            status,
            message,
            date: formatDate(history.created_at, {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }),
            title: formatDate(history.created_at, {
              month: 'long',
              year: 'numeric'
            }),
            user: history.user_details
          }
        }).filter(item => item.message !== null) // Remove null values
      }
    },

    getActiveSubmissionHistory: (state) => {
      return toRaw(state.activeSubmissionHistory)
    },
    getRevisedFields: (state) => {
      return state.revisedFields
    }
  },
  actions: {
    async initialize (force = false, params?: SubmissionFilterParamsType) {
      const appStore = useAppStore()
      if ((this.submissions.length > 0) && !force) return

      appStore.$state.loading = true
      const subsFromStore = await get('submissions')
      if (typeof subsFromStore !== 'undefined') {
        this.submissions = JSON.parse(subsFromStore) as unknown as SubmissionsType
      }
      try {
        const { data, error } = await client.GET('/api/submissions/', { params: { query: { ...params } } })
        // TOTO: Check that the return type is compatible
        // Update the openAPI return value and the typescript expectation
        if (typeof data !== 'undefined') {
          this.submissions = data as unknown as SubmissionsType
          await set('submissions', JSON.stringify(this.submissions))
        } else if (typeof error !== 'undefined') { console.error(error) }
      } catch (e) { console.warn(e) } finally {
        appStore.$state.loading = false
      }
    },
    /**
     * update status of the existing submission
     * @param formKey string
     * @param status number
     */
    updateStatusOfExistingSubmission (formKey: string, status: number) {
      this.submissions.map(submission => {
        if (submission.key === formKey) {
          submission.status = status
        }
        return submission
      })
    },

    async setSubmissionStatus (body: SubmissionStatusType) {
      const { data, error } = await client.POST('/api/submissions/set-status', { body })
      if (typeof data !== 'undefined') {
        const activeRevisionSubmission = this.activeRevisionSubmission
        const activeSubmission = this.activeSubmission
        await this.getSubmissionHistory(body.form_key)
        // update submission status on the state
        if (typeof activeRevisionSubmission !== 'undefined' && activeRevisionSubmission !== null) {
          activeRevisionSubmission.status = body.status
          this.activeRevisionSubmission = activeRevisionSubmission
        } else {
          if (typeof activeSubmission !== 'undefined') {
            activeSubmission.status = body.status
            this.activeSubmission = activeSubmission
          }
        }
        this.updateStatusOfExistingSubmission(body.form_key, body.status) // update status on the submissions state
      } else {
        toast.warning(useI18NStore().gettext('Submission status was not retrieved successfully'))
      }
      return { data, error }
    },

    async uploadFile (body: FormData) {
      // Waiting on support from the openapi typescript generator
      // It currently supports only JSON
      // const { data } = await client.POST('/api/submissions/file', { body })
      const r = await new ApiService().post('/api/submissions/file', body)
      if (r.success) {
        toast.success(useI18NStore().gettext('File uploaded successfully'))
      } else {
        toast.warning(useI18NStore().gettext('File did not upload successfully'))
      }
      return r
    },

    async deleteFile (id: number): Promise<boolean> {
      const { response } = await client.DELETE('/api/submissions/file/{submission_file_id}/delete', { params: { path: { submission_file_id: id } } })
      if (response.status == API_STATUS.API_NO_CONTENT) {
        toast.success(useI18NStore().gettext('File deleted successfully'))
      } else if (response.status == 401) {
        toast.warning(useI18NStore().gettext('You do not have permission to delete this file'))
      } else {
        toast.warning(useI18NStore().gettext('An error occured, the file could not be deleted'))
      }
      return response.status == API_STATUS.API_NO_CONTENT
    },

    async getSubmissionFiles (key: string) {
      try {
        const { data } = await client.GET('/api/submissions/{submission_key}/file-list', { params: { path: { submission_key: key } } })

        if (typeof data !== 'undefined') {
          return data.filter(dt => dt.deleted !== true && dt.comment === DATA_COLLECTION_FILE_LABEL)
        } else {
          return []
        }
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getSubmissionHistory (key: string) {
      const { data, error } = await client.GET('/api/submissions/{submission_id}/history', { params: { path: { submission_id: key }, query: {} } })
      if (typeof data !== 'undefined') this.activeSubmissionHistory = data.response
      if (typeof error !== 'undefined') console.error(error)
    },

    setActiveFile (files: UploadedFileType[]) {
      this.activeFile = files
    },

    setActiveSubmission (formKey: string) {
      this.activeSubmission = this.submissions?.find(
        (s) => s.key === formKey
      )
    },

    /**
     * set activeRevisionSubmission based on selected history options
     * @param oldValues fields value
     * @returns void
     */
    setActiveRevision (oldValues?: Record<string, any>) {
      const activeSubmission: SubmissionType = { ...this.activeSubmission as SubmissionType }
      if (typeof oldValues === 'undefined') { // if oldValues is undefined , it will reset the activeRevisionSubmission state
        this.activeRevisionSubmission = activeSubmission
        return
      }
      this.revisedFields = typeof oldValues.fields === 'undefined' || typeof activeSubmission.fields === 'undefined' ? [] : findDifferentFields(activeSubmission?.fields ?? {}, oldValues.fields) // will generate the updated fields for the highlighting
      if (typeof oldValues.fields !== 'undefined') {
        activeSubmission.fields = oldValues.fields // set the old values as current values
      }

      if (typeof oldValues.status !== 'undefined') {
        activeSubmission.status = oldValues.status
      }
      this.activeRevisionSubmission = activeSubmission
    },

    unSetActiveRevision () {
      this.activeRevisionSubmission = null
    },

    async updateSubmission (key: string, formType: string, fields: Record<string, never >, showToast = true) {
      const { data, error } = await client.PUT('/api/submissions/update', { body: { key, form_type: formType, fields } })
      if (typeof data !== 'undefined') {
        // update the existing submission data
        this.submissions = this.submissions.map((obj: SubmissionType) => {
          if (obj.key === key) {
            return { ...obj, fields: data.fields }
          }
          return obj
        })
        // get latest history data
        this.setActiveSubmission(key)
        await this.getSubmissionHistory(key)
        if (showToast) {
          toast.success({
            component: SubmissionMessage,
            props: {
              message: 'The submission was updated successfully',
              formKey: key
            },
            listeners: {
              viewSubmission: async () => await router.push({
                name: 'formDetail',
                params: {
                  formKey: key
                }
              })
            }
          })
          // toast.success(useI18NStore().gettext('The submission was updated successfully'))
        }
      } else {
        toast.error(useI18NStore().gettext(error))
      }
    },

    async update (key: string, formType: string, fields: Record<string, never >) {
      const { data } = await client.PUT('/api/submissions/update', { body: { key, form_type: formType, fields } })
      if (typeof data === 'undefined') throw new Error('Something wrong with submission update API')
      return true
    },

    async deleteRepeater (formKey: string, repeaterId: string): Promise<boolean> {
      const { data } = await client.DELETE('/api/submissions/delete-repeater', {
        body: {
          submission: formKey,
          part: repeaterId
        }
      })

      return typeof data !== 'undefined'
    }
  }
})
